import CommonIcon from '@/components/common-icon';
import { ExternalLink } from '@/components/link';
import { FACEBOOK, TELEGRAM } from '@/core/shared';
import { useAppContext } from '@/core/store';
import { MediaInfo } from '@/core/utils';
import css from 'styled-jsx/css';

export const CommunityLogo = () => {
  const { locale } = useAppContext();
  const telegram = TELEGRAM[locale] || TELEGRAM['en'];
  const _facebook = FACEBOOK[locale] || FACEBOOK['en'];
  const server = [
    {
      url: 'https://coinmarketcap.com/exchanges/bydfi/',
      logo: 'external-coinmarketcap-grey-0',
    },
    {
      url: 'https://twitter.com/BYDFi',
      logo: 'external-twitter-grey-0',
    },
    {
      url: telegram,
      logo: 'external-telegram-grey-0',
    },
    {
      url: _facebook,
      logo: 'external-facebook-grey-0',
    },
    {
      url: 'https://www.instagram.com/bydfi_official/',
      logo: 'external-insgram-grey-0',
    },
    {
      url: 'https://www.linkedin.com/company/bydfi',
      logo: 'external-linkedin-grey-0',
    },
    {
      url: 'https://www.youtube.com/@BYDFiOfficial',
      logo: 'external-youtube-grey-0',
    },
    {
      url: 'https://discord.gg/VJjYhsWegV',
      logo: 'external-discord-0',
    },
    {
      url: 'https://medium.com/bydfi',
      logo: 'external-medium-grey-0',
    },
    {
      url: 'https://www.coingecko.com/en/exchanges/bydfi',
      logo: 'external-coingecko-grey-0',
    },
    {
      url: 'https://www.reddit.com/r/BYDFiCryptoExchange/',
      logo: 'external-reddit-grey-0',
    },
  ];
  return (
    <>
      {server?.map((item, key) => {
        return (
          <ExternalLink href={item.url} key={key} className='community-logo'>
            <CommonIcon name={item.logo} size={25} />
            <style jsx>{styles}</style>
          </ExternalLink>
        );
      })}
    </>
  );
};
const styles = css`
  :global(.community-logo) {
    margin-right: 24px;
    :global(img) {
      @media ${MediaInfo.mobile} {
        margin-top: 10px;
      }
    }
    @media ${MediaInfo.mobile} {
      line-height: 20px;
    }
    @media ${MediaInfo.tablet} {
      line-height: 40px;
    }
  }
`;
